var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"route-container",attrs:{"id":"home"}},[_c('header',[_c('div',{staticClass:"title"},[_vm._v("— Snoppify —")]),_c('search-dropdown',{attrs:{"options":{ search: 'Search term' }}})],1),(_vm.player.isPlaying)?_c('h1',[_vm._v("Now playing")]):_c('h1',[_vm._v("Paused")]),(_vm.currentTrack)?_c('div',{staticClass:"current-track"},[(_vm.currentTrack.album)?_c('img',{attrs:{"src":_vm.currentTrack.album.images[1].url,"alt":""}}):_vm._e(),_c('div',{staticClass:"current-track__track-info"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentTrack.name))]),_c('div',{staticClass:"artist"},_vm._l((_vm.currentTrack.artists),function(artist,index){return _c('span',{key:index},[_c('span',[_vm._v(_vm._s(artist.name))]),(index + 1 < _vm.currentTrack.artists.length)?_c('span',[_vm._v(",")]):_vm._e()])}),0)]),(_vm.currentTrack.snoppify)?_c('div',{staticClass:"current-track__user-info"},[_c('div',{staticClass:"user-image",style:({
          'background-image':
            'url(' + _vm.currentTrack.snoppify.issuer.profile + ')',
        })}),_c('div',{staticClass:"user"},[_c('div',{staticClass:"title"},[_vm._v("Added by")]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.currentTrack.snoppify.issuer.displayName))]),_c('div',{staticClass:"upvotes"},[_vm._v(" "+_vm._s(_vm.currentTrack.snoppify.votes.length)+" upvotes ")])])]):_vm._e()]):_vm._e(),(_vm.player.status)?_c('div',{staticClass:"progress"},[_c('div',{staticClass:"progress_inner"},[_c('div',{staticClass:"progress_inner_bar",style:({ width: 100 * _vm.player.status.fraction + '%' })})]),_c('div',{staticClass:"progress_status"},[_c('span',[_vm._v(_vm._s(_vm.player.status.progress))]),_c('span',[_vm._v(_vm._s(_vm.player.status.duration))])])]):_vm._e(),_c('h1',[_vm._v("Queue")]),(_vm.queue)?_c('transition-group',{staticClass:"song-list",attrs:{"name":"song-list","tag":"ul"}},_vm._l((_vm.queue),function(track,index){return _c('li',{key:track.id},[_c('track-item',{attrs:{"track":track,"index":index + 1}})],1)}),0):_vm._e(),_c('h1',[_vm._v("Friends")]),_c('div',{staticClass:"friends-container"},[_c('button',{staticClass:"friends-button",attrs:{"disabled":_vm.user.friends.length == 0},on:{"click":function($event){return _vm.showFriends()}}},[_vm._v(" Show your "+_vm._s(_vm.user.friends.length)+" "),(_vm.user.friends.length == 1)?_c('span',[_vm._v("friend")]):_c('span',[_vm._v("friends")])])]),_c('hr'),_c('div',[_c('h1',[_vm._v("Share the party!")]),_c('p',[_c('button',{staticClass:"snopp-btn",staticStyle:{"margin":"auto"},on:{"click":function($event){_vm.showShareModal = true;
          _vm.generateShareQR();
          _vm.generateWifiQR();}}},[_vm._v(" Share party! ")])]),(_vm.showShareModal)?_c('modal',{on:{"close":function($event){_vm.showShareModal = false}}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('h1',[_vm._v("SnoppiCode")]),_c('p',{staticClass:"share-snoppi-code"},[_vm._v(_vm._s(_vm.snoppiCode))]),_c('h1',[_vm._v("Join the party!")]),_c('div',{staticClass:"share-qr-code"},[_c('canvas',{ref:"qrCanvas"})]),(_vm.wifiQR)?_c('div',[_c('h1',[_vm._v("Connect to wi-fi")]),_c('div',{staticClass:"share-qr-code"},[_c('canvas',{ref:"wifiQRCanvas"})])]):_vm._e()])]):_vm._e()],1),_c('hr'),_c('p',[_vm._v(" Logged in as "),_c('b',[_vm._v(_vm._s(_vm.user.displayName))])]),_vm._m(0),_c('hr'),_c('h1',[_vm._v("Make some noise")]),_c('div',{staticClass:"soundboard"},_vm._l(([
        'honk',
        'applause',
        'orgasm',
        'whistle',
        'yeah',
        'wilhelm',
        'airhorn',
        'brrrap',
        'rastafari',
        'inception',
        'mario1',
        'mario2' ]),function(sound){return _c('button',{key:sound,staticClass:"soundboard__btn snopp-btn",on:{"click":function($event){return _vm.playSound(sound)}}},[_vm._v(" "+_vm._s(sound)+" ")])}),0),(_vm.user.admin)?_c('div',[_c('hr'),_c('button',{on:{"click":_vm.play}},[_vm._v("Play")]),_c('button',{on:{"click":_vm.pause}},[_vm._v("Pause")]),_c('button',{on:{"click":_vm.playNext}},[_vm._v("Next track")]),_c('br'),_c('button',{on:{"click":_vm.playPlaylist}},[_vm._v("Play playlist")]),_c('button',{on:{"click":_vm.emptyPlaylist}},[_vm._v("Empty playlist")]),_c('button',{on:{"click":_vm.emptyQueue}},[_vm._v("Empty queue")]),_c('br'),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.setBackupPlaylist(_vm.backupUrl)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.backupUrl),expression:"backupUrl"}],attrs:{"placeholder":"Paste a playlist uri"},domProps:{"value":(_vm.backupUrl)},on:{"input":function($event){if($event.target.composing){ return; }_vm.backupUrl=$event.target.value}}}),_c('button',{attrs:{"type":"submit"}},[_vm._v("Set")])]),_c('p',[_vm._v(" Backup playlist: "),(_vm.backupPlaylist)?_c('span',[_c('b',[_vm._v(_vm._s(_vm.backupPlaylist.name))]),_vm._v(" ("+_vm._s(_vm.backupPlaylist.owner.display_name)+") ")]):_c('span',[_vm._v("(not set)")])])]):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{attrs:{"action":"/logout"}},[_c('input',{staticClass:"snopp-btn",attrs:{"type":"submit","value":"Logout"}})])}]

export { render, staticRenderFns }